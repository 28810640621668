import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import {
  Link
} from "react-router-dom";
import { forwardRef } from 'react';
import { Tabs, Tab } from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const { FaIcon, FaStack } = require('react-fa-icon');
const axios = require("axios")

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const getBit = (number, bitPosition) => {
  return (number & (1 << bitPosition)) === 0 ? 0 : 1;
}

let chart = null;
function App() {
  const [dataContainer, setDataContainer] = useState([]);
  const [done, setDone] = useState(true);
  const createDataChart = async (historico) => {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;

    let data = [];
    historico.forEach((row) => {
      let chartDate = moment(row.cntDh, "DD/MM/YYYY HH:mm:ss").toDate();
      data.push({
        date: chartDate,
        "year": moment(row.cntDh, "DD/MM/YYYY HH:mm:ss").format("HH:mm"),
        "label": moment(row.cntDh, "DD/MM/YYYY HH:mm:ss").format("HH:mm"),
        "setPoint": row.cntStp,
        "supply": row.cntSpl,
        "return": row.cntRet
      })
    })

    chart.data = data;
    chart.dateFormatter.inputDateFormat = "HH:mm";

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.baseInterval = { timeUnit: "minute", count: 1 };

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.title.text = "TEMPERATURA";
    // valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.StepLineSeries());
    series.dataFields.dateX = "year";
    series.dataFields.valueY = "setPoint";
    // series.tooltipText = "{valueY.value}";
    series.dataFields.labelX = "label";
    series.tooltipText = "{labelX}: [b]{valueY}[/] Set Point";
    series.name = "Set Point";
    series.strokeWidth = 3;

    series = chart.series.push(new am4charts.StepLineSeries());
    series.dataFields.dateX = "year";
    series.dataFields.valueY = "supply";
    // series.tooltipText = "{valueY.value}";
    series.dataFields.labelX = "label";
    series.tooltipText = "{labelX}: [b]{valueY}[/] Supply";
    series.name = "Supply";
    series.strokeWidth = 3;

    series = chart.series.push(new am4charts.StepLineSeries());
    series.dataFields.dateX = "year";
    series.dataFields.valueY = "return";
    // series.tooltipText = "{valueY.value}";
    series.dataFields.labelX = "label";
    series.tooltipText = "{labelX}: [b]{valueY}[/] Return";
    series.name = "Return";
    series.strokeWidth = 3;

    // var bullet = series.bullets.push(new am4charts.CircleBullet());
    // bullet.circle.stroke = am4core.color("#fff");
    // bullet.circle.strokeWidth = 2;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.fullWidthLineX = true;
    chart.cursor.lineX.strokeWidth = 0;
    chart.cursor.lineX.fill = chart.colors.getIndex(2);
    chart.cursor.lineX.fillOpacity = 0.1;

    chart.scrollbarX = new am4core.Scrollbar();
    chart.legend = new am4charts.Legend();

  }
  // url: 'http://127.0.0.1:3000/graphql',
  // url: 'http://159.69.33.24:8042/graphql',
  const getAllData = async () => {
    axios({
      url: 'http://159.69.33.24:8042/graphql',
      method: 'post',
      data: {
        query: `
          query HistoricoContainer {
            historicoContainer(first: 500 offset: 0) {
              cntDh
              cntStp
              cntSpl
              cntRet
              cntSer
              cntHor
              cntInp
              cntOut
              cntCmb
              cntLat
              cntLng
              veiculo {
                veiPlc
                rasSer
              }
            }
          }
          `
      }
    }).then((result) => {
      const { historicoContainer } = result.data.data;
      setDataContainer(historicoContainer);
      createDataChart(historicoContainer);
      setDone(false);
    });
  }

  useEffect(() => {
    getAllData();
    const interval = setInterval(() => {
      console.log("Atualizando......");
      getAllData();
    }, 60  * 1000);
    return () => clearInterval(interval);
  }, [])

  const getLinkUrl = (data) => {
    return `https://www.google.com/maps/preview?q=Modulo:${data.veiculo.rasSer}+-+Serial:${data.cntSer}+Registro:${data.cntDh}+%29%40+${data.cntLat},${data.cntLng}&t=h`;
  }

  return (
    <div>
      <MuiThemeProvider>
        <Tabs>
          <Tab label="CONTAINER MONITOR" >
            <MaterialTable
              icons={tableIcons}
              isLoading={done}
              columns={[
                {
                  title: 'RASTREADOR', field: 'cntSer',
                  cellStyle: {
                    backgroundColor: '#000',
                    color: '#f4e123'
                  },
                  headerStyle: {
                    backgroundColor: '#000',
                    color: '#f4e123',
                  },
                  render: rowData => rowData.veiculo.rasSer,
                  sorting: false,
                },
                {
                  title: 'SERIAL CONTAINER', field: 'cntSer',
                  cellStyle: {
                    backgroundColor: '#000',
                    color: '#f4e123'
                  },
                  headerStyle: {
                    backgroundColor: '#000',
                    color: '#f4e123',
                  },
                  render: rowData => rowData.cntSer ? rowData.cntSer : 'DESCONECTADO',
                  sorting: false,
                },
                { title: 'DATA E HORA GPS', field: 'cntDh', sorting: false, },
                { title: 'SET POINT', field: 'cntStp', type: 'numeric', sorting: false, },
                { title: 'SUPPLY', field: 'cntSpl', type: 'numeric', sorting: false, },
                { title: 'RETURN', field: 'cntRet', type: 'numeric', sorting: false, },
                { title: 'HORÍMETRO', field: 'cntHor', type: 'numeric', sorting: false, },
                { title: 'COMBUSTÍVEL', field: 'cntCmb', type: 'numeric', sorting: false, },
                {
                  title: 'ENTRADAS', field: 'cntInp',
                  render: rowData => <div style={{ width: 128, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntInp, 0) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} title="IGNIÇÃO" />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntInp, 1) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} title="MOTOR" />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntInp, 2) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntInp, 3) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntInp, 4) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntInp, 5) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} title="DISJUNTOR" />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntInp, 6) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                  </div>, 
                  sorting: false
                },
                {
                  title: 'SAÍDAS',
                  field: 'cntOut',
                  render: rowData => <div style={{ width: 76, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntOut, 0) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntOut, 1) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntOut, 2) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                    <div style={{ height: 16, width: 16, backgroundColor: getBit(rowData.cntOut, 3) ? 'green' : 'gray', borderRadius: 8, cursor: 'pointer' }} />
                  </div>, 
                  sorting: false
                },
                {
                  title: 'Localização',
                  field: 'cntLat',
                  render: rowData => <div><a target="blank" href={getLinkUrl(rowData)}>Visualizar</a></div>, 
                  sorting: false,
                },
              ]}
              data={dataContainer}
              options={{
                pageSize: 25,
                toolbar: false,
                headerStyle: {
                  backgroundColor: '#000',
                  color: '#f4e123'
                },
                rowStyle: {
                  height: 30,
                }
              }}
            />
          </Tab>
          <Tab label="CONTAINER GRÁFICO" >
            <div id="chartdiv" style={{ height: '80vh', width: '80wh' }}></div>
          </Tab>
        </Tabs>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
